import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { TntButton } from '../components/TntButton';
import { setDonationDetails } from '../redux/donationReducer';
import { useAppDispatch } from '../hooks';
import { DonationSuggestionProps, DonationState } from '../types';
import { colors } from '../colors';
import { sendClick } from '../apiCalls';
import { clickIds } from '../clickIds';
import { wrapWithHeaderFooter } from '../components/HeaderFooterWrapped';

import '../styles/donationv2.css';

const donationSuggestions = [5, 10, 20, 50, 100, 500];


const DonationSuggestionCard = ({ amount, onClick, selected }: DonationSuggestionProps) => (
  <div
    className="donation-suggestion-button"
    onClick={onClick}
    style={selected ? { border: `solid 1px ${colors.tntBlue}` } : undefined}
  >
    {`$${amount}`}
  </div>
);

const DonationPage = () => {

  const [donationAmount, setDonationAmount] = useState(0);
  const [suggestionsVisible, setSuggestionsVisible] = useState(true);

  const [isEnteringManually, setIsEnteringManually] = useState(false);
  const [manualDonationAmount, setManualDonationAmount] = useState('');
  
  const [name, setName] = useState('');
  const [affiliation, setAffiliation] = useState('');

  const dispatch = useAppDispatch();
  const onDonorStateChange = (newState: DonationState) => {
    dispatch(setDonationDetails(newState));
  };

  const DonationSubmit = ({ visible } : { visible: boolean }) => (
    <form
      // action="http://localhost:5000/api/v1/stripecheckout"
      action="https://tnt-server1.azurewebsites.net/api/v1/stripecheckout"
      method="POST"
      style={{ visibility: visible ? 'visible' : 'hidden' }}
    >
      <input type="hidden" name="donationId" value={uuid()} />
      <input type="hidden" name="donationAmount" value={isEnteringManually ? parseInt(manualDonationAmount.replace(/,/g, '')) : donationAmount} />
      <input type="hidden" name="name" value={name} />
      <input type="hidden" name="affiliation" value={affiliation} />
      <TntButton
        size="large"
        backgroundColor={colors.tntBlue}
        buttonText="Donate"
      />
    </form>
  );

  useEffect(() => {
    sendClick(clickIds.donationPage);
  }, []);

  return (
    <div className="donation-container">
      <div className="donation-card">
        <div className="donation-top">
          <span>Thank you for donating</span>
          <span id="one-time">One Time</span>
        </div>
        {suggestionsVisible ? (
          <>
            <div className="donation-suggestions">
              {donationSuggestions.map((item: number) => (
                <DonationSuggestionCard
                  key={item}
                  amount={item}
                  onClick={() =>{
                    if (isEnteringManually) {
                      setIsEnteringManually(false);
                      setManualDonationAmount('');
                    }
                    setDonationAmount(item);
                    onDonorStateChange({
                      donationAmount: item,
                      donorName: name,
                      donorAffiliation: affiliation
                    });
                  }}
                  selected={!isEnteringManually && item === donationAmount}
                />
              ))}
            </div>
            <div className="input-box">
              <span className="prefix">$</span>
              <input
                className="currency-input"
                placeholder="Or, enter a custom amount..."
                value={manualDonationAmount === '' ? manualDonationAmount : parseInt(manualDonationAmount).toLocaleString()}
                onChange={(e) => {
                  const val = e.target.value === '' ? e.target.value : parseInt(e.target.value.replace(/,/g, ''));
                  if (val === '' || val) {
                    if (!isEnteringManually) {
                      setIsEnteringManually(true);
                      setDonationAmount(0);
                    }
                    if (isEnteringManually && val === '') 
                      setIsEnteringManually(false);
                    setManualDonationAmount(val.toString());
                  }
                }}
              />
            </div>
            {
              (isEnteringManually && manualDonationAmount) || (!isEnteringManually && donationAmount > 0) ? (
                <TntButton
                  size="medium"
                  backgroundColor={colors.tntBlue}
                  buttonText="Next"
                  onClick={() => {
                    setSuggestionsVisible(false);
                    sendClick(clickIds.donationPortalNextButton);
                  }}
                />
              ) : null
            }
          </>
        ) : (
          <div className="donor-info-content">
            <div className="donor-info-subtitle">Tell us a little about you</div>
            <div className="info-input-container">
              <input
                className="donor-input"
                type="text"
                value={name}
                onChange={(e) => {
                    setName(e.target.value);
                    onDonorStateChange({
                      donationAmount: donationAmount,
                      donorName: e.target.value,
                      donorAffiliation: affiliation
                    });
                }}
                placeholder="Enter your name"
              />
              <input
                className="donor-input"
                type="text"
                value={affiliation}
                onChange={(e) => {
                  setAffiliation(e.target.value);
                  onDonorStateChange({
                    donationAmount: donationAmount,
                    donorName: name,
                    donorAffiliation: e.target.value
                  });
              }}
                placeholder="Enter employer/university"
              />
            </div>
            <DonationSubmit visible={name !== '' && affiliation !== ''} />
          </div>
        )}
      </div>
    </div>
  );
};

export default wrapWithHeaderFooter(DonationPage);
