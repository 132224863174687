import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

import { PageState } from '../types';

// Define the initial state using that type
const initialState: PageState = {
  page: 'home'
};

export const pageSlice = createSlice({
  name: 'donation',
  initialState,
  reducers: {
    setPageDetails: (state, action: PayloadAction<PageState>) => {
      console.log(state.page);
      state.page = action.payload.page;
    }
  }
});

export const { setPageDetails } = pageSlice.actions;
export const selectPageDetail = (state: RootState) => state.donationDetails;
export default pageSlice.reducer;
