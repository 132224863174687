import React, { useState } from 'react';
import { wrapWithHeaderFooter } from '../components/HeaderFooterWrapped';
import { MeetOurTeamContent } from '../types';
import { teamMembers } from '../team';
import '../styles/MeetOurTeam.css';
import { sendClick } from '../apiCalls';

import { TntButton } from '../components/TntButton';
import { colors } from '../colors';

const TeamMemberModal = ({ name, role, bio, image, handleClose }: MeetOurTeamContent & { handleClose: () => void }) => {
  return (
      <div className="modal-overlay" onClick={handleClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <img src={image} alt={name} />
            <span>
              <h1>{name}</h1>
              <h3>{role}</h3>
            </span>
          </div>
          <div className="modal-bio">
            {bio.split('NP').map((p, index) => (
              <p key={index}>{p}</p>
            ))}
          </div>
          <TntButton
            size="small"
            backgroundColor={colors.tntBlue}
            buttonText="Close"
            onClick={handleClose}
          />
        </div>
      </div>
  );
};

const TeamMember = ({ name, role, bio, image }: MeetOurTeamContent) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="team-member">
      <img src={image} alt={name} onClick={() => { setIsModalOpen(true); document.body.style.overflow = 'hidden'; sendClick(`ClickV01-${name}`) }} />
      <h3>{name}</h3>
      <p className="role">{role}</p>
      {isModalOpen ? (
          <TeamMemberModal
            name={name}
            role={role}
            bio={bio}
            image={image}
            handleClose={() => { setIsModalOpen(false); document.body.style.overflow = 'auto'; }}
          />
      ) : null}
    </div>
  );
};


const MeetOurTeam = () => {
  return (
    <div className="meet-the-team">
      <h2 className="meet-our-team-title">Meet the team</h2>
      <div className="team-container">
        {teamMembers.map(({ name, role, bio, image}: MeetOurTeamContent) => (
          <TeamMember
            key={name}
            name={name}
            role={role}
            bio={bio}
            image={image}
          />
        ))}
      </div>
    </div>
  );
};

export default wrapWithHeaderFooter(MeetOurTeam, true, true);
