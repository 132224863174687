export const colors = {
    tntYellow: '#FED71C',
    tntOrange: '#E67419',
    tntMagenta: '#FC284C',
    tntPurple: '#8519E6',
    tntBlue: '#0C69FF',
    tntRed: '#EF3340',
    black: '#000000',
    white: '#FFFFFF',
};
