import React, { useEffect } from 'react';
import { wrapWithHeaderFooter } from '../components/HeaderFooterWrapped';
import '../styles/GetInvolved.css';
import { sendClick } from '../apiCalls';
import { clickIds } from '../clickIds';

const GetInvolvedPage = () => {

    useEffect(() => {
        sendClick(clickIds.getInvolved);
    }, []);

    return (
        <div className="get-involved-container">
            <div className="get-involved-title">Conservation. Education. Connection.</div>
            <p className="get-involved-content">
                Tiburones n Tortugas (TnT) is working on student experiences and eco-tourism 
                options at our research station in fantastic Playa Ganado, Costa Rica. Check back
                here at a later date or follow us on social media to stay in the loop!
            </p>
        </div>
    );
};

export default wrapWithHeaderFooter(GetInvolvedPage);
