import React from 'react';
import { useWindowSize } from 'react-use';
import { NavLink } from 'react-router-dom';
import { TntButton } from '../components/TntButton';
import { colors } from '../colors';

import logo from '../img/logo.svg';
import '../styles/header.css';
import { setPageDetails } from '../redux/pageReducer';
import { useAppDispatch, useAppSelector } from '../hooks';
import { PageState, HeaderLinkProps } from '../types';

import { linkInfo } from '../constants';

const screenWidthThreshold = 600;

const Buttons = ({ width }: { width: number }) => {
    
    const SignupButton = () => (
        <TntButton
          size={width > screenWidthThreshold ? "medium" : "small"}
          backgroundColor="#f8f8f8"
          textColor={colors.black}
          buttonText="Sign Up"
          hasBorder
          extraStyle={{ margin: 4 }}
        />
    );
    const LoginButton = () => (
        <TntButton
            size={width > screenWidthThreshold ? "medium" : "small"}
            backgroundColor={colors.black}
            buttonText="Log In"
        />
    );

    return (
        <div className="header-buttons">
            <SignupButton />
            <LoginButton />
        </div>
    );
};

export const Header = () => {
    const { width } = useWindowSize();
    const dispatch = useAppDispatch();
    
    const setPage = (pageState: PageState) => {
        dispatch(setPageDetails(pageState));
    };

    const { page } = useAppSelector(state => state.pageDetails);

    const HeaderLink = ({ name, linkId, link }: HeaderLinkProps) => (
        <NavLink
            className="header-nav"
            id={(page !== 'home' && linkId === page) ? "red" : ""}
            to={link}
            onClick={() => setPage({ page: linkId })}
        >
            {name}
        </NavLink>  
    );

    return (
        <div className="header">
            {
                width > screenWidthThreshold ? (
                <NavLink className="logo" to="/">
                    <img src={logo} alt="Logo" />
                </NavLink>
                ) : null
            }
            <div className="right-links">
                {
                    linkInfo.map(({ name, linkId, link }) => (
                        <HeaderLink
                            name={name}
                            linkId={linkId}
                            link={link}
                        />
                    ))
                }
            </div>
            <Buttons width={width} />
        </div>
    );

};
