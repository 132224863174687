import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { sendDonationUpdate } from '../apiCalls';
import { wrapWithHeaderFooter } from '../components/HeaderFooterWrapped';


const AfterDonationScreen = () => {

    const [searchParams] = useSearchParams();
    const success = searchParams.get('status') === 'true';
    const donationId = searchParams.get('donationId') ?? '';

    useEffect(() => {
        if (donationId)
            sendDonationUpdate(donationId, success);
    }, [donationId, success]);

    return (
        <div className="about-us-container">
            <div className="about-us-title">{success ? 'Thank you for your donation' : 'Donation canceled'}</div>
        </div>
    );
};

export default wrapWithHeaderFooter(AfterDonationScreen);
