import React from 'react';
import { TntButtonProps } from '../types';
import { colors } from '../colors';

import '../styles/button.css';

/*
Enforce standard sizing for TnT button for now.
*/
const sizes = {
    small: { width: 85, height: 30 },
    medium: { width: 115, height: 45 },
    large: { width: 200, height: 60 },
};

export const TntButton = ({ size, backgroundColor, textColor, onClick, buttonText, hasBorder, extraStyle } : TntButtonProps) => {

    let buttonSize;
    if (size === 'small' || size === 'medium') buttonSize = sizes[size];
    else buttonSize = sizes['large'];

    const color = textColor ? textColor : colors.white;
    const border = hasBorder ? 'solid 1px black' : 'none';

    const inlineStyle = { ...buttonSize, backgroundColor, color, border, ...extraStyle };

    return (
      <button
        className="tnt-button"
        style={inlineStyle}
        onClick={onClick}
        type="submit"
      >
        {buttonText}
      </button>
    );
};
