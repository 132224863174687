import jim from './img/jim.jpeg';
import leslie from './img/leslie.jpeg';
import maddie from './img/maddie.jpeg';
import randall from './img/randall.jpeg';
import sam from './img/sam.jpeg';
import jake from './img/jake.png';
import daniel from './img/daniel.jpeg';
import six from './img/six.jpeg';
import constantin from './img/constantin.png';
import edna from './img/edna.jpg';


export const teamMembers = [
    {
      name: 'Randall Arauz',
      role: 'President and Co-Founder',
      bio: `Randall Arauz is a Costa Rican field biologist and marine conservationist. He started his career protecting nesting sea turtles and became involved in sea turtles fisheries interactions, which quickly led him to study the impact of longline fisheries on endangered marine species.  He exposed Costa Rica's role in the international shark fin trade, and initiated a campaign in 2002 that has had domestic, regional, and global impacts.  He is currently focused on studying the migratory movements of highly migratory endangered marine species to develope multiple use coastal marine protected areas and expanding a strict protection policy in waters surrounding Cocos Island National Park and along the Submarine  Cocos Ridge that connects Cocos Island to the Galapagos Islands.  Randall currently works as the Marine Conservation Policy Advisor for Fins Attached Marine Research and Conservation.`,
      image: randall
    },
    {
      name: 'Jim Porter',
      role: 'Ecologist',
      bio: `James W. Porter is the Josiah Meigs Distinguished Professor of Ecology, Emeritus at the
            University of Georgia. Dr. Porter received both his Bachelor’s and Ph.D. degrees from Yale.
            He has testified before Congress three times about the effects of (1) climate change on
            coral reefs, (2) munitions contamination on Puerto Rican coral reefs, and (3) most recently, the
            global biodiversity crisis.NP

            Dr. Porter has published more than 120 papers in peer review scientific journals, ten
            percent of which are in either Science, Nature, or the Proceedings of the National Academy of
            Science. He has authored three books. His current research examines coral reef dynamics in
            Costa Rica and the Eastern Tropical Pacific.NP
            
            In 2005, he received the Eugene P. Odum Award for environmental education from the
            Ecological Society of America, and in 2006, he was elected President of Sigma Xi, the Scientific
            Honor Society with more than 160,000 members worldwide, including all living Nobel
            Laureates. In 2021 he received the Coral Reef Society’s top Eminence in Research Award.NP
            
            Dr. Porter's award winning photographs have appeared in Life Magazine and the New
            York Times. His work has been featured on the ABC World News, NBC Nightly News, and CNN.
            His documentary film, Chasing Coral, to which he contributed as the Chief Scientific Advisor
            and a Principal Cast Member, won the Audience Choice Award at the 2017 Sundance Film
            Festival, and then went on to win a 2017 Peabody Award and the 2017 Emmy for Best Nature
            Documentary`,
      image: jim
    },
    {
      name: 'Jake McGrew',
      role: 'Executive Director',
      bio: `Jake is a co-founder of TNT and is a serial entrepreneur with with a background in marine ecology, fundraising, and building capacity for conservation initiatives. He has designed and executed research projects in East Africa, and South and Central America with a focus on landscape and seascape-level wildlife corridor conservation. He’s committed to protecting the marine biodiversity of the Eastern Tropical Pacific.`,
      image: jake
    },
    {
      name: 'Daniel Arauz',
      role: 'Biologist',
      bio: `Daniel Arauz is a field biologist and project coordinator for the Rescue Center for Endangered Marine Species (CREMA) in Costa Rica. As a biologist and professional diver, Daniel Arauz has been involved in marine conservation since a very young age. Since 2017 he has managed a sea turtle monitoring program in coastal communities where he promotes environmental education, science, and sustainable fisheries practices to protect the habitat of the critically endangered hawksbill sea turtle. In 2018 Daniel was selected as part of the first cohort of National Geographic Photo Ark EDGE fellows, which helps young conservationists in protecting evolutionarily distinct and globally endangered species.`,
      image: daniel
    },
    {
      name: 'Leslie Ruyle',
      role: 'Ecologist',
      bio: `Dr. Leslie Ruyle is an ecologist working across disciplines to create innovative solutions for conservation, conflict, and development. She holds a PhD in Ecology from the University of Georgia, served as a Peace Corps Volunteer in Ghana, West Africa, and has managed university-based initiatives for NSF and USAID. Having lived in four countries and traveled to over 80 countries in her career, she has broad experience in international applied conservation and research including stints at the Savannah River Ecology Laboratory, Smithsonian Tropical Research Institute- Panama, Honduran Coral Reef Foundation, Applied Biodiversity Science Program, Earthwatch, and the Center on Conflict and Development. Her work has been recognized with the UN’s Equator Prize Initiative and Dean’s Award for Interdisciplinary teams. Currently, she is building a program in DRC focused on supporting entrepreneurship in regions of conflict, conservation concern, and limited connectivity (EC3).`,
      image: leslie
    },
    {
      name: 'Edna De la Llata Quiroga',
      role: 'Senior Policy Advisor',
      bio: `Edna is an environmental science engineer from México, she has a master’s degree in Wildlife Management and Conservation from the National University of Costa Rica, she graduated with honors. She has experience working with drones, in her bachelor’s degree she used the Structure from Motion (SfM) technique to create a Digital Elevation Model (DEM) to delineate catchment boundaries and the steam network, and compare SfM technique with LiDAR data. She also did aerial surveys for monitoring sharks with Pelagios Kakunjá at La Paz, BCS, México.NP

      She has worked with marine species such as whales, she did photoidentification of Balaenoptera physalus and Balaenoptera musculus and used a photographic technique to measure the whales, at the CICIMAR-IPN with the Dr. Diane Gendron. She did her master’s degree thesis using Stable Isotopes technique to study the trophic ecology of juvenile bull sharks (Carcharhinus leucas) in two estuarine systems at Costa Rica, with CREMA and the support of Randall Arauz.NP
      
      She also had experience working with other wildlife species. She did a protocol with the National University of Costa Rica for the correct Tapirus Bairdi (danta) sighting, that the Arenal-Tempisque Conservation area Arenal is implementing. She did a comparison of fruit removal in Ficus colubrinae between birds and the bat Ectophylla alba in a Costa Rican rain forest. She did research about habitat segregation of the black iguana, Ctenosaura similis at the National Park Santa Rosa, Costa Rica, and she reported a new geographic distribution record for the snake Leptodeira rubricata at the Nicoya peninsula, Guanacaste, Costa Rica.NP
      
      She is very interested in protected the marine ecosystem and the marine species, especially the sharks that plays a very important role in helping to maintain the balanced ecosystems that keep our oceans healthy`,
      image: edna
    },
    {
      name: 'Sam Morton',
      role: 'Software Engineer',
      bio: `Sam Morton is a Masters student in Computer Science at Dartmouth College. With a background in full-stack software engineering and machine learning, Sam brings a unique set of skills to TnT. As the software developer for Tiburones n Tortugas, Sam created and manages the organization's web presence and data infrastructure, utilizing his technical skills to make a positive impact. In his free time, Sam enjoys running and cycling, and ran cross country and track for Dartmouth between 2017 and 2022, serving as captain of the team during the 2021-2022 season.`,
      image: sam
    },
    {
      name: 'Sixtine Fleury',
      role: 'Conservation Neuroscientist',
      bio: `Sixtine is a neuroscience PhD student at Dartmouth College. She earned her bachelor’s degree at Bowdoin College (’19) where she studied neuroscience and political science. She’s interested in the nexus of neuroscience and marine conservation. A long-time diver and ocean passionate, Sixtine spent her summer of 2018 at the South African Shark Conservancy in Hermanus, Cape Town, SA to study shark behavior and conservation. Her hope is to merge her passion for neuroscience and marine conservation, and use her behavioral background to find innovative ways to protect marine biodiversity and wildlife.`,
      image: six
    },
    {
      name: 'Maddie Nobili',
      role: 'Student Board Member',
      bio: `Maddie Nobili is a recent graduate of Dartmouth College, where she earned a Bachelor of Arts degree in Hispanic Studies and Biological Sciences with a concentration in Ecology. During her time at Dartmouth, she was an NCAA Division 1 athlete, competing in Cross Country, Indoor and Outdoor Track & Field. Her current interests include conservation, natural resources management, field science, wildlife biology, and avian ecology, with a specific focus on the relationship between birds and the changing environment. Maddie hopes to pursue a career that allows her to investigate this relationship and to engage with communities to promote conservation efforts and action against climate change. Maddie grew up on Cape Cod, MA and currently resides in Massachusetts. In her leisure time, she enjoys running, reading, baking, knitting, and watching cooking shows.`,
      image: maddie
    },
    {
      name: 'Constantin Fleury',
      role: 'Student Board Member',
      bio: `Constantin Fleury is a senior at Colby College majoring in Biology (concentration in ecology and evolution) and minoring in mathematics. He has a keen interest in conservation and environmental policy, which aligns with his passion for understanding and preserving the natural world. Constantin was one of the first TnT students to go to Costa Rica for research. During his time there, he studied sustainable fisheries, participated in turtle conservation, and performed bull shark tissue sampling. In his free time, Constantin enjoys skiing and cooking. He is also the captain of the Colby rugby team.`,
      image: constantin
    }
];
