import { HeaderLinkProps } from './types';

export const linkInfo: HeaderLinkProps[] = [
    {
        name: 'Home',
        linkId: 'home',
        link: '/'
    },
    {
        name: 'About',
        linkId: 'about',
        link: '/about'
    },
    {
        name: 'Get Involved',
        linkId: 'involved',
        link: '/involvement'
    },
    {
        name: 'Donate',
        linkId: 'donate',
        link: '/donate'
    }
];

export const aboutUsParagraph = `Tiburones n Tortugas (TnT) is a non-profit organization dedicated to protecting the habitat of sharks and turtles in Costa Rica. They have a team of experts, including renowned conservationists, professors, and National Geographic photographers. Led by Randall Arauz, TnT has a successful history of conservation work, from removing WWII explosives to affecting conservation legislation. TnT also offers educational opportunities and eco-tourist experiences at their research station in Playa Ganado, allowing visitors to contribute to important research and conservation work.`
