import React from 'react';
import { NavLink } from 'react-router-dom';

import { linkInfo } from '../constants';

import '../styles/footer.css';

import logo from '../img/logo.svg';
import { setPageDetails } from '../redux/pageReducer';
import { PageState } from '../types';
import { useAppDispatch } from '../hooks';

/*
First row of the footer just re-includes the links to our social media
*/
const SocialLinks = () => (
    <div className="social-links">
        <a
            className="link"
            href="https://www.linkedin.com/company/tiburonesntortugas/"
            target="_blank"
            rel="noopener noreferrer"
        >
            LinkedIn
        </a>
        <a
            className="link"
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
        >
            Twitter
        </a>
        <a
            className="link"
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
        >
            Instagram
        </a>
    </div>
);

const CopyrightLine = () => (
    <div className="copyright-footer">
        &copy; {new Date().getFullYear()} Tiburones n Tortugas
    </div>
);

export const Footer = () => {

    const dispatch = useAppDispatch();
    const setPage = (pageState: PageState) => {
        dispatch(setPageDetails(pageState));
    };

    return (
        <div className="footer">
            <div className="footer-wrapper">
                <div className="logo-group">
                    <NavLink className="logo" to="/">
                        <img src={logo} alt="" />
                    </NavLink>
                    <CopyrightLine />
                </div>
                <div className="links">
                {
                    linkInfo.map(({ name, linkId, link }) => (
                        <NavLink
                            className="link"
                            to={link}
                            onClick={() => setPage({ page: linkId })}
                        >
                            {name}
                        </NavLink>
                    ))
                }
                </div>
                <SocialLinks />
            </div>
        </div>
    );
};
