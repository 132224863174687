import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { wrapWithHeaderFooter } from '../components/HeaderFooterWrapped';

import { sendClick } from '../apiCalls';
import '../styles/landing.css';
import { clickIds } from '../clickIds';

import turt from '../img/turt.jpg';

const Landing = () => {

    useEffect(() => {
        sendClick(clickIds.landing);
    }, []);

    return (
        <div className="landing">
            <div>
                <div className="landing-msg">
                    <div>Protect Costa Rica's</div>
                    <div>incredible</div>
                    <div>coastal ecosystems with us.</div>
                </div>
                <NavLink className="emboldened" to="/donate">Join us here</NavLink>
            </div>
            <img className="landing-img" src={turt} alt="" />
        </div>
    );
};

export default wrapWithHeaderFooter(Landing);
