import React, { FC } from 'react';
import { Header } from '../views/Header';
import { Footer } from '../views/Footer';

import '../styles/headerFooter.css';


export const wrapWithHeaderFooter = (Component: FC, hasHeader = true, hasFooter = true) => {
    return () => (
        <>
            {hasHeader ? <Header /> : null}
            <div className="wrapped-component-wrapper">
                {<Component />}
            </div>
            {hasFooter ? <Footer /> : null}
        </>
    );
};

