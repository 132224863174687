import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ScrollToTop } from './components/ScrollToTop';

// local views
import AboutUs from './views/AboutUs';
import  GetInvolvedPage from './views/GetInvolved';
import MeetOurTeam from './views/MeetOurTeam'; 
import Landing from './views/Landing';
import DonationPage from './views/DonationPageV2';
import AfterDonationScreen from './views/AfterDonationScreen';

import './styles/app.css';

export const App = () => (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/meet" element={<MeetOurTeam />} />
        <Route path="/involvement" element={<GetInvolvedPage />} />
        <Route path="/donate" element={<DonationPage />} />
        <Route path="/donationlobby" element={<AfterDonationScreen />} />
      </Routes>
    </BrowserRouter>
);
