import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

import { DonationState } from '../types';

// Define the initial state using that type
const initialState: DonationState = {
  donationAmount: 0,
  donorName: '',
  donorAffiliation: ''
};

export const donationSlice = createSlice({
  name: 'donation',
  initialState,
  reducers: {
    setDonationDetails: (state, action: PayloadAction<DonationState>) => {
      state.donationAmount = action.payload.donationAmount;
      state.donorName = action.payload.donorName;
      state.donorAffiliation = action.payload.donorAffiliation;
    }
  }
});

export const { setDonationDetails } = donationSlice.actions;
export const selectDonationDetail = (state: RootState) => state.donationDetails;
export default donationSlice.reducer;
