import axios from 'axios';

// const SERVER_PATH = 'http://localhost:5000/api/v1';
const SERVER_PATH = 'https://tnt-server1.azurewebsites.net/api/v1';
// const SERVER_PATH = 'dummy';
const CLIENT = 'http://www.tnt.eco';

export const sendClick = (clickId: string, thenCallback?: () => void) => {

    axios.post(`${SERVER_PATH}/clicks`, {
        clickId,
        date: new Date().toISOString()
    }, { 
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': CLIENT
        }
    }).then(res => {
        if (thenCallback) thenCallback();
        else console.log(res.data);
    })
    // .catch(err => {
    //     console.error('There was error', err);
    // });
};

export const sendDonationUpdate = (donationId: string, success: boolean) => {

    axios.post(`${SERVER_PATH}/updatedonationstatus`, {
        donationId,
        donationStatus: success ? 'success' : 'canceled'
    }, { 
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': CLIENT
        }
    }).then(res => {
        console.log(res.data);
    })
    .catch(err => {
        console.error(err);
    });
};

