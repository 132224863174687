import React, { useEffect, useState } from 'react';
import { wrapWithHeaderFooter } from '../components/HeaderFooterWrapped';

import { aboutUsParagraph } from '../constants';
import { teamMembers } from '../team';

import { sendClick } from '../apiCalls';
import { clickIds } from '../clickIds';
import '../styles/AboutPage.css';
import { MeetOurTeamContent } from '../types';
import { colors } from '../colors';
import { TntButton } from '../components/TntButton';

const TeamMemberModal = ({ name, role, bio, image, handleClose }: MeetOurTeamContent & { handleClose: () => void }) => {
    return (
        <div className="modal-overlay" onClick={handleClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <img src={image} alt={name} />
              <span>
                <h1>{name}</h1>
                <h3>{role}</h3>
              </span>
            </div>
            <div className="modal-bio">
              {bio.split('NP').map((p, index) => (
                <p key={index}>{p}</p>
              ))}
            </div>
            <TntButton
              size="small"
              backgroundColor={colors.tntBlue}
              buttonText="Close"
              onClick={handleClose}
            />
          </div>
        </div>
    );
  };
  
  const TeamMember = ({ name, role, bio, image }: MeetOurTeamContent) => {
  
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    return (
      <div className="team-member">
        <span>
          <img className="team-member-img" src={image} alt={name} onClick={() => { setIsModalOpen(true); document.body.style.overflow = 'hidden'; sendClick(`ClickV01-${name}`) }} />
          <h3 className="team-member-name">{name}</h3>
        </span>
        <div className="role">{role}</div>
        {isModalOpen ? (
            <TeamMemberModal
              name={name}
              role={role}
              bio={bio}
              image={image}
              handleClose={() => { setIsModalOpen(false); document.body.style.overflow = 'auto'; }}
            />
        ) : null}
      </div>
    );
  };

const AboutUs = () => {

    useEffect(() => {
        sendClick(clickIds.about);
    }, []);

    return (
        <div className="about-us-container">
            <div className="about-us-title">Our Mission</div>
            <div className="about-us-content">
                {aboutUsParagraph}
            </div>
            <div className="about-us-title">Our Team</div>
            <div className="team-container">
                {teamMembers.map(({ name, role, bio, image}: MeetOurTeamContent) => (
                    <TeamMember
                        key={name}
                        name={name}
                        role={role}
                        bio={bio}
                        image={image}
                    />
                ))}
            </div>
        </div>
    );
};

export default wrapWithHeaderFooter(AboutUs);
